import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../API/apiService";
import { ReactSession } from "react-client-session";

ReactSession.setStoreType("localStorage");

interface FormData {
  username?: string;
  password?: string;
  remember_me?: boolean;
}

const Signin: React.FC = () => {
  const [status, setStatus] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    username: "",
    password: "",
    remember_me: true,
  });
  const userRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const key = event.target.id;
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      const response = await loginUser({
        username: formData.username!,
        password: formData.password!,
      });

      if (response.success) {
        const userData = response.data;
        // Guardar los datos en el localStorage
        localStorage.setItem("name", JSON.stringify(userData.user));
        localStorage.setItem("employeeData", JSON.stringify(userData.employee));
        localStorage.setItem("authenticated", JSON.stringify(true));

        // Configurar la sesión
        ReactSession.set("usertype", userData.user?.usertype);
        ReactSession.set("sessionUser", userData.user);
        ReactSession.set("employeeData", userData.employee);

        setStatus(true);
        setTimeout(() => setStatus(null), 2500);
        setTimeout(() => navigate("/home/timecard"), 1000);
      } else {
        setStatus(false);
        setTimeout(() => setStatus(null), 2500);
        setFormData({
          username: "",
          password: "",
        });
      }
    } catch (error) {
      console.error('Error during login:', error);
      setStatus(false);
      setTimeout(() => setStatus(null), 2500);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h4>Sign in to start your session</h4>
        <label>
          Username
          <input
            className="inputs"
            type="text"
            placeholder="Username"
            id="username"
            autoComplete="off"
            required
            onChange={handleChange}
            ref={userRef}
            value={formData?.username}
          />
        </label>
        <label>
          Password
          <input
            className="inputs"
            type="password"
            placeholder="******"
            id="password"
            required
            value={formData?.password}
            onChange={handleChange}
          />
        </label>
        <div className="nav">
          <span>
            <input
              type="checkbox"
              id="remember_me"
              checked={formData?.remember_me}
              onChange={handleChange}
            />
            <h3>
              <strong>Remember Me</strong>
            </h3>
          </span>
          <button className="btn-pry" type="submit">
            Sign In
          </button>
        </div>
        <span className="misc">
          <p onClick={() => navigate("recover")}>I forgot my password</p>
        </span>
        {status ? (
          <div className="form__status active">Login Successfully</div>
        ) : status === false ? (
          <div className="form__status">Login details incorrect</div>
        ) : null}
      </form>
    </>
  );
};

export default Signin;
