import React, { useContext, useEffect, useState } from "react";
import { appContext } from "../../pages/Main";
import { getDepartmentById } from "../../API/apiService";

interface UserListProps {
  renderUser(id: number): void;
}

const UserList: React.FC<UserListProps> = ({ renderUser }) => {
  const employees = useContext(appContext);
  const [departments, setDepartments] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    const fetchDepartments = async () => {
      const departmentMap: { [key: number]: string } = {};
      if (employees) {
        for (let employee of employees) {
          const departmentName = await getDepartmentById(employee.department_id, employee.admin);
          departmentMap[employee.department_id] = departmentName;
        }
      }
      setDepartments(departmentMap);
    };

    fetchDepartments();
  }, [employees]);

  return (
    <div className="results">
      {employees?.map((list) => (
        <span
          key={list?.id}
          onClick={() => {
            renderUser(list?.id);
          }}
        >
          <h4>{list?.name}</h4>
          <small>{departments[list?.department_id] || "Loading..."}</small>
        </span>
      ))}
    </div>
  );
};

export default UserList;
