import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { recoverPassword } from "../../API/apiService"; // Importa la nueva función

interface formData {
  email?: string;
  password?: string;
  cpassword?: string;
  className?: string;
}

const PasswordRecovery: React.FC<formData> = () => {
  const [status, setStatus] = useState<boolean | null>(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState<formData>({
    email: "",
    password: "",
    cpassword: "",
  });
  const userRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (null !== userRef.current) {
      userRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle change event
  const handleChange = (event: any): void => {
    const key: string = event.target.id;
    const value: any = event.target.value;

    setFormData({ ...formData, [key]: value });
  };

  // Submission form function
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (formData.password !== formData.cpassword) {
      return alert("Passwords do not match");
    }

    try {
      const response = await recoverPassword({
        email: formData.email,
        password: formData.password,
      });

      if (response.success) {
        setStatus(true);
        setTimeout(() => {
          navigate("/login");
        }, 1000);

        setTimeout(() => {
          setStatus(null);
        }, 2500);
      } else {
        setStatus(false);
        setTimeout(() => {
          setStatus(null);
        }, 2500);
      }
    } catch (error) {
      console.error('Error during password recovery:', error);
      setStatus(false);
      setTimeout(() => {
        setStatus(null);
      }, 2500);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h4>Recover Account!</h4>
        <label className="required">Email</label>
        <input
          className="inputs"
          type="email"
          placeholder="Email"
          id="email"
          autoComplete="off"
          required
          onChange={handleChange}
          ref={userRef}
          value={formData.email}
        />

        <label className="required">Password</label>
        <input
          className="inputs"
          type="password"
          placeholder="******"
          id="password"
          required
          value={formData.password}
          onChange={handleChange}
        />

        <label className="required">Confirm Password</label>
        <input
          className="inputs"
          type="password"
          placeholder="******"
          id="cpassword"
          required
          value={formData.cpassword}
          onChange={handleChange}
        />
        <div className="nav">
          <button className="btn-pry" type="submit">
            Reset password
          </button>
        </div>
        <span className="misc">
          <p onClick={() => navigate("/login")}>Back to login Page</p>
        </span>
        {status ? (
          <div className="form__status active">Password reset success</div>
        ) : status === false ? (
          <div className="form__status">Account with email not found!</div>
        ) : null}
      </form>
    </>
  );
};

export default PasswordRecovery;
