import React, { useState, useEffect } from "react";
import { RiLogoutCircleLine } from "react-icons/ri";
import { MdMenu } from "react-icons/md";
import Aside from "./nav/nav";
import { MainProps } from "../pages/Main";
import { useNavigate } from "react-router-dom";

const Header: React.FC<MainProps> = () => {
  const navigate = useNavigate();
  const [toggleNavBar, setToggle] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(window.innerWidth < 850);

  useEffect(() => {
    const handleResize = () => {
      setIsButtonVisible(window.innerWidth < 850);
    };

    // Establecer el tamaño inicial de la ventana
    handleResize();

    // Agregar el listener de evento para el cambio de tamaño
    window.addEventListener("resize", handleResize);

    // Limpiar el listener cuando el componente se desmonte
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header>
      {isButtonVisible && (
        <button
          onClick={() => {
            setToggle(!toggleNavBar);
          }}
        >
          <MdMenu size="3rem" color="#dce1eb" />
          {toggleNavBar ? <Aside /> : null}
        </button>
      )}
      <span>
        <RiLogoutCircleLine size="1.5rem" color="#dce1eb" />
        <h3
          onClick={() => {
            localStorage.setItem("authenticated", JSON.stringify(false));
            navigate("/login");
          }}
        >
          Logout
        </h3>
      </span>
    </header>
  );
};

export default Header;
