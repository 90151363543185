import React, { useEffect, useState } from "react";
import { userInterface } from "../interfaces/interface";
import "react-circular-progressbar/dist/styles.css";
import { ReactSession } from "react-client-session";
import { getTimestamps } from "../API/apiService"; // Importa la nueva función
import EmployeesSummary from "../components/cards/EmployeesSummary";

interface Analyticsprops {
  currentuser?: userInterface;
  setCurrentUser?(obj: userInterface): void;
}

const Analytics: React.FC<Analyticsprops> = ({ currentuser }) => {
  const [, setData] = useState({
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
  });

  useEffect(() => {
    const fetchTimestamps = async (id: number) => {
      try {
        const response = await getTimestamps(id);
        if (response.success) {
          setData(response.data);
        } else {
          console.log("Employee Not Found");
        }
      } catch (err) {
        console.error(err);
      }
    };
    const userId = ReactSession.get("sessionUser")?.id;
    if (userId) {
      fetchTimestamps(userId);
    }
  }, [currentuser]);

  return (
    <section className="analytics__view">
      <EmployeesSummary />
      {/* <article className="left">
        <Welcomeinfo />
        <Usercard />
      </article>
      <article className="right">
        {loading && (
          <Chart id="chart" palette="Soft" dataSource={dataSource}>
            <CommonSeriesSettings
              argumentField="day"
              valueField="number"
              type="bar"
              ignoreEmptyPoints={true}
            />
            <SeriesTemplate nameField="day" />
            <Title
              text="Weekly performance"
              subtitle={`${ReactSession.get("sessionUser")?.name}`}
            >
              <Font color="white" />
            </Title>
          </Chart>
        )}
      </article> */}
    </section>
  );
};

export default Analytics;
