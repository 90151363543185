import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Signin from './components/forms/signin';
import PasswordRecovery from './components/forms/passwordrecovery';
import { LoaderProvider } from './utils/LoaderContext';
import Register from './pages/Register';
import PaymentSuccess from './pages/PaymentSuccess';

ReactDOM.render(
  <React.StrictMode>
    <LoaderProvider>
      <Router>
        <Routes>
            <Route path="/register" element={<Register />}>
            </Route>

            <Route path="/" element={<Login />}>
              <Route path="///" element={<Signin />} />
              <Route path="recover" element={<PasswordRecovery />} />
              <Route path="signin" element={<Signin />} />
            </Route>

            <Route path="/login" element={<Login />}>
              <Route path="/login/" element={<Signin />} />
              <Route path="signin" element={<Signin />} />
              <Route path="recover" element={<PasswordRecovery />} />
            </Route>
            <Route path="/home/*" element={<App />} />

            <Route path="/payment-success" element={<PaymentSuccess />} /> 
        </Routes>
      </Router>
    </LoaderProvider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);
