import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getActiveSubscription } from '../API/apiService';

const PaymentSuccess: React.FC = () => {

    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const customerId = searchParams.get('customerId');
    const navigate = useNavigate();

    useEffect(() => {
        const handleRateSelection = async (userId) => {
            try {
                console.log('Entra en handleRateSelection');
                console.log(userId);
                console.log(customerId);
                
                const result = await getActiveSubscription(customerId, Number(userId));

                if (result.success) {
                    // Redirigir al home después de la actualización
                    navigate("/home");
                } else {
                    console.error('Failed to set rate_selected', result.error);
                    navigate("/home");
                }
            } catch (error) {
                console.error('Error setting rate_selected:', error);
            }
        };

        if (userId) {
            handleRateSelection(userId);
        }
    }, [userId, customerId, navigate]);

    return (
        <div>
            <h2>Procesando tu petición...</h2>
        </div>
    );
};

export default PaymentSuccess;
