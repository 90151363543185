import React, { useContext, useEffect, useState } from "react";
import Userinfo from "../components/cards/Userinfo";
import UserList from "../components/cards/UserList";
import Welcomeinfo from "../components/cards/Welcomeinfo";
import Searchbar from "../components/forms/Searchbar";
import { appContext } from "./Main";
import { userInterface } from "../interfaces/interface";
import { getTimeRecord, updateRestRecord, updateTimeRecord } from "../API/apiService";
import { accContext } from "../App";
import { useLoader } from "../utils/LoaderContext";

export interface TimecardProp {
  currentuser?: userInterface;
  setCurrentUser?(obj: userInterface): void;
  postTimeStamp?(id: number, obj: userInterface): void;
  filterUsers?(str: string): void;
  activeclassname?: string;
  deleteUser?(id: number): void;
  status?: boolean;
  className?: string;
}

const Timecard: React.FC<TimecardProp> = ({
  currentuser,
  setCurrentUser,
  filterUsers,
}) => {
  const { setLoading } = useLoader();
  const [timerecords, setTimerecords] = useState<any[]>([]);
  const [userToLoad, setUserToLoad] = useState<userInterface | null>(null);
  const [existRecord, setExistRecord] = useState<boolean | null>(null);
  const [isWorking, setIsWorking] = useState<boolean | null>(null);
  const [isResting, setIsResting] = useState<boolean | null>(null);
  const employees = useContext(appContext);
  const accUser = useContext(accContext);

  useEffect(() => {
    let isMounted = true; // Bandera para verificar si el componente está montado

    const loadTimeRecords = async () => {
      const userId = userToLoad?.id || accUser?.id;
      if (userId) {
        const response = await getTimeRecord(userId);
        if (response.success && isMounted) { // Verificar la bandera antes de actualizar el estado
          setTimerecords(response.data);
          const record = response.data.find((r) => r.week === getWeekStartDate());
          if (response.data.length === 0) {
            setExistRecord(false);
            setIsWorking(false);
            setIsResting(false);
          } else {
            if (record) {
              setExistRecord(true);
              setIsWorking(record.is_working);
              setIsResting(record.is_resting);
            } else {
              setIsWorking(false);
              setIsResting(false);
            }
          }
        }
      }
    };
    
    loadTimeRecords();

    return () => {
      isMounted = false; // Limpiar la bandera cuando el componente se desmonte
      setTimerecords([]); // Limpiar el estado al desmontar
      setExistRecord(null);
      setIsWorking(null);
      setIsResting(null);
    };
  }, [userToLoad, accUser]);

  const renderUser = (id: number): void => {
    const user = employees?.find((element) => element.id === id);
    if (user) {
      setCurrentUser && setCurrentUser(user);
      setUserToLoad(user); // Trigger the effect to reload time records
    } else {
      setCurrentUser && setCurrentUser(accUser);
    }
  };

  let timeIn: Date | undefined;

  const clockInTime = async () => {
    if (!currentuser) {
      return alert("Please select your account!");
    }

    timeIn = new Date();
    const today = getDay(timeIn);
    const timeRecordData = {
      [`${today}_in`]: formatTime(timeIn.toTimeString().split(' ')[0]) // Guardar solo la parte hh:mm:ss
    };

    try {
      if (currentuser.id) {
        // Primero, obtener los registros actuales
        const fetchResponse = await getTimeRecord(currentuser.id);
        if (fetchResponse.success) {
          const record = fetchResponse.data.find((r) => r.week === getWeekStartDate());

          if (record) {
            // Actualizar el registro existente
            const updateRecordResponse = await updateTimeRecord(setLoading, currentuser.id, timeRecordData, 'in');
            if (updateRecordResponse.success) {
              const fetchUpdatedResponse = await getTimeRecord(currentuser.id);
              if (fetchUpdatedResponse.success) {
                setTimerecords(fetchUpdatedResponse.data);
                setExistRecord(true);
                setIsWorking(true);
                setIsResting(false);
              }
            }
          } else {
            // Crear un nuevo registro de tiempo
            const newRecordData = {
              week: getWeekStartDate(),
              [`${today}_in`]: formatTime(timeIn.toTimeString().split(' ')[0])
            };
            const createRecordResponse = await updateTimeRecord(setLoading, currentuser.id, newRecordData, 'in');
            if (createRecordResponse.success) {
              const fetchNewResponse = await getTimeRecord(currentuser.id);
              if (fetchNewResponse.success) {
                setTimerecords(fetchNewResponse.data);
                setExistRecord(true);
                setIsWorking(true);
                setIsResting(false);
              }
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const clockOutTime = async () => {
    if (!currentuser) {
      return alert("Please select your account!");
    }
  
    const timeOut = new Date(); // Hora de salida actual
    const today = getDay(timeOut);
  
    try {
      if (currentuser.id) {
        // Obtener los registros actuales
        const fetchResponse = await getTimeRecord(currentuser.id);
        if (fetchResponse.success) {
          const record = fetchResponse.data.find((r) => r.week === getWeekStartDate());
  
          if (record) {
            // Si ya hay un registro para la semana actual
            const timeRecordData = {
              [`${today}_out`]: formatTime(timeOut.toTimeString().split(' ')[0]), // Guardar solo la parte hh:mm:ss
              [`${today}`]: `${record[`${today}_in`]}-${formatTime(timeOut.toTimeString().split(' ')[0])}` // Rango de tiempo
            };
  
            const updateRecordResponse = await updateTimeRecord(setLoading, currentuser.id, timeRecordData, 'out');
            if (updateRecordResponse.success) {
              const fetchUpdatedResponse = await getTimeRecord(currentuser.id);
              if (fetchUpdatedResponse.success) {
                setTimerecords(fetchUpdatedResponse.data);
                setExistRecord(true);
                setIsWorking(false);
                setIsResting(false);
              }
            }
          } else {
            // Crear un nuevo registro si no existe uno para la semana actual
            const newRecordData = {
              week: getWeekStartDate(),
              [`${today}_out`]: formatTime(timeOut.toTimeString().split(' ')[0]),
            };
            const createRecordResponse = await updateTimeRecord(setLoading, currentuser.id, newRecordData, 'out');
            if (createRecordResponse.success) {
              const fetchNewResponse = await getTimeRecord(currentuser.id);
              if (fetchNewResponse.success) {
                setTimerecords(fetchNewResponse.data);
                setExistRecord(true);
                setIsWorking(false);
                setIsResting(false);
              }
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const clockInRest = async () => {
    if (!currentuser) {
      return alert("Please select your account!");
    }

    try {
      await updateRestRecord(setLoading, currentuser.id, {}, 'rest_in');
      setIsResting(true);
    } catch (e) {
      console.error(e);
    }
  };

  const clockOutRest = async () => {
    if (!currentuser) {
      return alert("Please select your account!");
    }

    try {
      await updateRestRecord(setLoading, currentuser.id, {}, 'rest_out');
      setIsResting(false);
    } catch (e) {
      console.error(e);
    }
  };

  const getDay = (date: Date): string => {
    const map = {
      "1": "monday",
      "2": "tuesday",
      "3": "wednesday",
      "4": "thursday",
      "5": "friday",
      "6": "saturday",
      "0": "sunday"
    };
    return map[date.getDay().toString()] || "unknown";
  };

  const formatTime = (timeString: string | null): string => {
    if (!timeString) return "N/A"; // Manejar valores nulos
    const [hours, minutes, seconds] = timeString.split(":");
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
  };

  const formatTimeToShow = (time: string): string => {
    // Elimina la parte de la zona horaria si existe
    const timeWithoutZone = time.split(/[+-]/)[0];
    return timeWithoutZone;
  };

  const getWeekStartDate = (): string => {
    const now = new Date();
    const day = now.getDay() || 7; // Get the day of the week, 1 = Monday, 7 = Sunday
    now.setDate(now.getDate() - day + 1); // Set to Monday
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const date = now.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${date}`; // Format yyyy-mm-dd
  };

  // Función para mostrar los pares de tiempos
  const formatTimePairs = (inTimes: string[], outTimes: string[]): string => {
    return inTimes.map((timeIn, index) => {
        const timeOut = outTimes[index];
        return timeIn && timeOut ? `${formatTimeToShow(timeIn)} a ${formatTimeToShow(timeOut)}` : '';
    }).filter(Boolean).join(', ');
  };

  // Dentro del componente Timecard
  return (
    <section className="timecard__view">
      <article className="left">
        <Welcomeinfo />
        <>
            {/* Mostrar el bloque solo si employees.length > 0 */}
            {employees.length > 0 && (
                <>
                    <Searchbar filterUsers={filterUsers} />
                    <div className="users-list">
                        <div className="user-title">
                            <h3>Lista de empleados</h3>
                            {/* <select
                                onChange={(e) => {
                                    filterUsers && filterUsers(e.target.value);
                                }}
                            >
                                <option value="all">Filter</option>
                                <option value="System Design">System Design</option>
                                <option value="sales">Sales</option>
                                <option value="hospitality">Hospitality</option>
                            </select> */}
                        </div>
                        {/* Mostrar la lista de usuarios siempre, pero puede estar vacía */}
                        <UserList renderUser={renderUser} />
                    </div>
                </>
            )}
        </>
    </article>
    <article className="right">
        <Userinfo currentuser={currentuser} />
        <div className="right-manage">
          <button className="btn-in btn" onClick={clockInTime} disabled={isWorking || isResting || !currentuser || (accUser.id !== currentuser.id)}>
            Entrada
          </button>
          <button className="btn-in btn" onClick={clockOutTime} disabled={!existRecord || !isWorking || isResting || !currentuser || (accUser.id !== currentuser.id)}>
            Salida
          </button>
          <button className="btn-in btn" onClick={clockInRest} disabled={!existRecord || isResting || !isWorking || !currentuser || (accUser.id !== currentuser.id)}>
            Descanso
          </button>
          <button className="btn-in btn" onClick={clockOutRest} disabled={!existRecord || !isResting || !isWorking || !currentuser || (accUser.id !== currentuser.id)}>
            Fin descanso
          </button>
      </div>
      <div className="forms-container">
          <h3>Registros</h3>
          <div className="overflow-y">
            {timerecords.map((record, index) => (
                <div className="timestamp-container" key={index}>
                    {record.week && <span className="week">Semana del {record.week}</span>}
                    {record.monday_in && record.monday_out && (
                        <span>Lunes: {formatTimePairs(record.monday_in, record.monday_out)}</span>
                    )}
                    {record.tuesday_in && record.tuesday_out && (
                        <span>Martes: {formatTimePairs(record.tuesday_in, record.tuesday_out)}</span>
                    )}
                    {record.wednesday_in && record.wednesday_out && (
                        <span>Miércoles: {formatTimePairs(record.wednesday_in, record.wednesday_out)}</span>
                    )}
                    {record.thursday_in && record.thursday_out && (
                        <span>Jueves: {formatTimePairs(record.thursday_in, record.thursday_out)}</span>
                    )}
                    {record.friday_in && record.friday_out && (
                        <span>Viernes: {formatTimePairs(record.friday_in, record.friday_out)}</span>
                    )}
                    {record.saturday_in && record.saturday_out && (
                        <span>Sábado: {formatTimePairs(record.saturday_in, record.saturday_out)}</span>
                    )}
                    {record.sunday_in && record.sunday_out && (
                        <span>Domingo: {formatTimePairs(record.sunday_in, record.sunday_out)}</span>
                    )}
                </div>
            ))}
          </div>
        </div>
      </article>
    </section>
  );
};

export default Timecard;
