import React, { useState, useEffect, useContext } from "react";
import { userInterface } from "../../interfaces/interface";
import { addEmployee, fetchDepartments } from "../../API/apiService";
import { Department } from "../../interfaces/department";
import { appContext } from "../../pages/Main";
import { useNavigate } from "react-router-dom";

export interface AddnewProps {
  setUsers(arr: userInterface[]): void;
  users: userInterface[];
  setCurrentUser(user: userInterface): void;
}

const AddNew: React.FC<AddnewProps> = ({ setUsers, users, setCurrentUser }) => {
  const [status, setStatus] = useState<boolean | null>(null);
  const [departments, setDepartments] = useState<Department[]>([]); // Estado para almacenar los departamentos
  const [addingNewDepartment, setAddingNewDepartment] = useState<boolean>(false); // Estado para manejar el nuevo departamento

  // Obteniendo el company_id del contexto
  const employees = useContext(appContext);
  const company_id = employees?.[0]?.company_id || 0;
  const navigate = useNavigate();
  
  // Hold user data
  const [formData, setFormData] = useState<userInterface>({
    name: "",
    surname: "",
    email: "",
    username: "",
    password: "",
    department_id: 0,
    company_id: company_id,
    department_name: "",
  });

  // Set focus
  useEffect(() => {
    setFormData((prevData) => ({ ...prevData }));
  }, []);

  // Fetch departments cuando se monta el componente o cambia el company_id
  useEffect(() => {
    if (company_id) {
      fetchDepartments(company_id).then((departments) => {
        setDepartments(departments);
      });
    }
  }, [company_id]);

  const handleChange = (event: any) => {
    const key = event.target.id;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
          ? 1
          : 0
        : key === "department_id"
        ? parseInt(event.target.value)
        : event.target.value;

    setFormData({ ...formData, [key]: value });
  };

  const handleDepartmentChange = (event: any) => {
    const value = event.target.value;

    if (value === "add_new") {
      setAddingNewDepartment(true);
      setFormData({ ...formData, department_id: 0 });
    } else {
      setAddingNewDepartment(false);
      setFormData({ ...formData, department_id: parseInt(value) });
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const finalFormData = { ...formData };

    // Si se está agregando un nuevo departamento, guardamos el nombre como string
    if (addingNewDepartment && finalFormData.department_id === 0) {
      finalFormData.department_id = 0; // Set department_id to 0 for new department
      finalFormData.department_name = formData.department_name || "";
    }

    finalFormData.company_id = company_id;
    console.log('company_id');
    console.log(company_id);

    try {
      const response = await addEmployee(finalFormData);

      console.log(response);
      

      if (response) {
        navigate("/home/admin/addnew");
        setStatus(true);
        setTimeout(() => {
          setStatus(null);
        }, 2500);
      } else {
        setStatus(false);
        setTimeout(() => {
          setStatus(null);
        }, 2500);
        setCurrentUser(response[0]);
        setUsers([...users, formData]);
        setFormData({
          name: "",
          surname: "",
          email: "",
          username: "",
          password: "",
          department_id: 0,
          department_name: "",
          company_id: company_id,
        });
        navigate("/home/admin/addnew")
      }
    } catch (err) {
      console.error('Error adding employee:', err);
    }
  };

  return (
    <form className="addnew" onSubmit={handleSubmit}>
      {status ? (
        <div className="form__status active">Employee Added Successfully</div>
      ) : status === false ? (
        <div className="form__status">Employee already Exists</div>
      ) : null}
      <div className="form-group">
        <label>Name</label>
        <input
          required
          id="name"
          type="text"
          className="inputs"
          value={formData?.name}
          onChange={handleChange}
          placeholder=""
        />
      </div>{" "}
      <div className="form-group">
        <label>Apellidos</label>
        <input
          required
          id="surname"
          type="text"
          className="inputs"
          value={formData?.surname}
          onChange={handleChange}
          placeholder=""
        />
      </div>
      <div className="form-group">
        <label>Usuario/DNI</label>
        <input
          required
          id="username"
          type="text"
          className="inputs"
          value={formData?.username}
          onChange={handleChange}
          placeholder=""
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          required
          id="email"
          type="email"
          className="inputs"
          value={formData?.email}
          onChange={handleChange}
          placeholder=""
        />
      </div>{" "}
      {!addingNewDepartment && ( // Oculta el select si se está añadiendo un nuevo departamento
        <div className="form-group">
          <label>Departamento</label>
          <select
            id="department_id"
            className="inputs"
            value={formData?.department_id}
            onChange={handleDepartmentChange}
          >
            <option value="0" disabled>
              Select a department
            </option>
            {departments.map((department) => (
              <option key={department.id} value={department.id}>
                {department.name}
              </option>
            ))}
            <option value="add_new">Añadir nuevo departamento</option>
          </select>
        </div>
      )}
      {addingNewDepartment && ( // Muestra el campo para añadir un nuevo departamento si se seleccionó "Añadir nuevo departamento"
        <div className="form-group">
          <label>Nombre del departamento</label>
          <input
            required
            id="department_name"
            type="text"
            className="inputs"
            value={formData?.department_name || ""}
            onChange={handleChange}
            placeholder=""
          />
        </div>
      )}
      <div className="form-group">
        <label>Password </label>
        <input
          required
          id="password"
          type="text"
          className="inputs"
          value={formData?.password}
          onChange={handleChange}
          placeholder=""
        />
      </div>
      <button className="btn-submit">Añadir Empleado</button>
    </form>
  );
};

export default AddNew;
