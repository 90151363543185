import React, { useEffect, useState } from "react";
import { fetchDepartments, updateEmployee } from "../../API/apiService"; // Importa las nuevas funciones
import { userInterface } from "../../interfaces/interface";
import { Department } from "../../interfaces/department";
import { useNavigate } from "react-router-dom";

interface Updateprops {
  currentuser: userInterface | null; // Asegúrate de que currentuser puede ser null
  setCurrentUser(obj: userInterface): void;
  setUsers(arr: userInterface[]): void;
  users: userInterface[];
}

const Update: React.FC<Updateprops> = ({
  currentuser,
  setCurrentUser,
  setUsers,
  users,
}) => {
  const [status, setStatus] = useState<boolean | null>(null);
  const [departments, setDepartments] = useState<Department[]>([]);
  const navigate = useNavigate();
  const company_id = currentuser?.company_id || 0;

  //hold user data
  const [formData, setFormData] = useState<userInterface>({
    name: "",
    surname: "",
    department_id: 1,
  });

  //set focus
  useEffect(() => {
    if (currentuser) {
      setFormData({
        name: currentuser.name || "",
        surname: currentuser.surname || "",
        department_id: currentuser.department_id || 1,
      });
    } else {
      setFormData({
        name: "",
        surname: "",
        department_id: 1,
      });
    }

    fetchDepartments(company_id).then((departments) => {
      setDepartments(departments);
    });
  }, [currentuser, company_id]);

  // Handle change event
  const handleChange = (event: any) => {
    const key = event.target.id;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
          ? 1
          : 0
        : event.target.id === "department_id"
        ? parseInt(event.target.value)
        : event.target.value;

    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      // Remover employee_name antes de la inserción
      const employeeData = {
        name: formData.name,
        surname: formData.surname,
        department_id: formData.department_id,
      };
      const response = await updateEmployee(currentuser?.id!, employeeData);

      if (response.success) {
        setCurrentUser(response.data);
        setStatus(true);
        setTimeout(() => {
          setStatus(null);
        }, 2500);

        navigate("/home/admin/update");
      } else {
        setStatus(false);
        setTimeout(() => {
          setStatus(null);
        }, 2500);
      }
    } catch (error) {
      console.error('Error during update:', error);
      setStatus(false);
      setTimeout(() => {
        setStatus(null);
      }, 2500);
    }
  };

  return (
    <form className="addnew" onSubmit={handleSubmit}>
      {status ? (
        <div className="form__status active">Usuario Actualizado</div>
      ) : status === false ? (
        <div className="form__status">Error al actualizar usuario</div>
      ) : null}
      <div className="form-group">
        <label>Nombre</label>
        <input
          required
          id="name"
          type="text"
          className="inputs"
          value={formData?.name || ""}
          onChange={handleChange}
          placeholder=""
        />
      </div>
      <div className="form-group">
        <label>Apellidos</label>
        <input
          required
          id="surname"
          type="text"
          className="inputs"
          value={formData?.surname || ""}
          onChange={handleChange}
          placeholder=""
        />
      </div>
      {!currentuser?.admin && ( 
        <div className="form-group">
          <label>Departmento</label>
          <select
            id="department_id"
            className="inputs"
            value={formData?.department_id || 1}
            onChange={handleChange}
          >
            {departments.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
            ))}
          </select>
        </div>
      )}
      <button className="btn-submit">Actualizar Usuario</button>
    </form>
  );
};

export default Update;
