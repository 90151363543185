import { createClient } from '@supabase/supabase-js';
const Stripe = require('stripe');

// Tu URL de Supabase y la clave de API (tú debes reemplazar estos valores)
const supabaseUrl = 'https://zuxgwvjaccebcdloinyo.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp1eGd3dmphY2NlYmNkbG9pbnlvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjE0MDQxNzMsImV4cCI6MjAzNjk4MDE3M30.gywYZV5COIV1wWze_5ATaogNkjDF-h99sGRMVnHDWjU';
const stripeKey = 'sk_test_51PvnV1ADSkogiMpe7yWrDTXu9EJGmCW4YUMabj6tZvbRobS57sGI8185McyVPmdFZzFczD1KdbDRtYAefkG5E25T00W82uBCfV';

// Crear una instancia del cliente de Supabase
export const supabase = createClient(supabaseUrl, supabaseKey);

// Obtén la API Key de Stripe desde tus variables de entorno
export const stripe = Stripe(stripeKey);

