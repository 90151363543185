import React, { useEffect, useState } from 'react';
import { TimecardProp } from '../../pages/Timecard';
import { getDepartmentById } from '../../API/apiService';

const Userinfo: React.FC<TimecardProp> = ({ currentuser }) => {
  const [departmentName, setDepartmentName] = useState<string>('Cargando...');

  useEffect(() => {
    console.log(currentuser);
    
    if (currentuser?.admin) {
      // Asegurarte de que la función devuelva un string
      getDepartmentById(0, currentuser.admin).then((response) => {
        
        if (typeof response === 'string') {
          setDepartmentName(response);
        } else {
          // Manejo de error si la respuesta no es un string
          setDepartmentName('Error al obtener el departamento');
        }
      }).catch(() => {
        setDepartmentName('Error al obtener el departamento');
      });
    }
    if (currentuser?.admin === false) {
      // Asegurarte de que la función devuelva un string
      getDepartmentById(currentuser.department_id, currentuser.admin).then((response) => {
        
        if (typeof response === 'string') {
          setDepartmentName(response);
        } else {
          // Manejo de error si la respuesta no es un string
          setDepartmentName('Error al obtener el departamento');
        }
      }).catch(() => {
        setDepartmentName('Error al obtener el departamento');
      });
    }
  }, [currentuser]);

  return (
    <div className="card">
      {/* <img src={currentuser?.avatar} alt="avatar" /> */}
      <h3>{currentuser?.name}</h3>
      <span className="card-info">
        <span>Tipo de usuario:</span>
        <p>{currentuser?.admin ? "Administrador" : "Usuario"}</p>
      </span>
      {/* <span className="card-info">
        <span>Puesto:</span>
        <p>{currentuser?.title}</p>
      </span> */}
      <span className="card-info">
        <span>Departamento:</span>
        <p>{departmentName}</p>
      </span>
    </div>
  );
};

export default Userinfo;
