import React, { useState } from 'react';
import { createCompany, createEmployee, registerUser } from '../API/apiService';
import { NavLink } from 'react-router-dom';

const Register = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        companyName: '',
        departments: [{
            id: 0,
            name: '',
            company_id: 0,
            created_at: '',
            updated_at: '',
        }], // Un departamento inicial
        employees: [{ name: '', email: '', department_id: '' }], // Un empleado inicial
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // const handleDepartmentChange = (index, value) => {
    //     const newDepartments = [...formData.departments];
    //     newDepartments[index].name = value;
    //     setFormData({ ...formData, departments: newDepartments });
    // };

    // const handleEmployeeChange = (index, field, value) => {
    //     const newEmployees = [...formData.employees];
    //     newEmployees[index] = {
    //         ...newEmployees[index],
    //         [field]: value,
    //     };
    //     setFormData({ ...formData, employees: newEmployees });
    // };

    // const addDepartment = () => {
    //     setFormData({
    //         ...formData,
    //         departments: [...formData.departments, {
    //             id: 0,
    //             name: '',
    //             company_id: 0,
    //             created_at: '',
    //             updated_at: '',
    //         }],
    //     });
    // };

    // const addEmployee = () => {
    //     setFormData({
    //         ...formData,
    //         employees: [...formData.employees, { name: '', email: '', department_id: '' }],
    //     });
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Registrar usuario
            const userData = await registerUser(formData.email, formData.password);

            // Crear empresa
            const company = await createCompany(formData.companyName);

            // Crear empleado administrador
            await createEmployee(formData.name, formData.username, formData.email, formData.password, userData.user.id, company.id, true);

            // Crear departamentos
            // await createDepartments(formData.departments, company.id);

            // Crear empleados adicionales
            // await createEmployees(formData.employees, company.id);

            alert('Registration successful!');
        } catch (error) {
            console.error('Error during registration:', error);
            alert(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div id="register">
            <div className="container__register">
                <h2>Register <span>for Free</span></h2>
                <form onSubmit={handleSubmit}>
                    <h4>Personal Information</h4>
                    <label>Name</label>
                    <input
                        className="inputs"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <label>Username</label>
                    <input
                        className="inputs"
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                    <label>Email</label>
                    <input
                        className="inputs"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <label>Password</label>
                    <input
                        className="inputs"
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />

                    {/* <h4>Company Information</h4> */}
                    <label>Company Name</label>
                    <input
                        className="inputs"
                        type="text"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        required
                    />

                    {/* <h4>Departments</h4>
                    {formData.departments.map((department, i) => (
                        <input
                            key={i}
                            className="inputs"
                            type="text"
                            placeholder={`Department ${i + 1}`}
                            value={department.name}
                            onChange={(e) => handleDepartmentChange(i, e.target.value)}
                        />
                    ))}
                    <button type="button" className="btn-pry" onClick={addDepartment}>
                        Add Department
                    </button>

                    <h4>Employees</h4>
                    {formData.employees.map((employee, i) => (
                        <div key={i}>
                            <label>Employee {i + 1} Name</label>
                            <input
                                className="inputs"
                                type="text"
                                placeholder={`Employee ${i + 1} Name`}
                                value={employee.name}
                                onChange={(e) => handleEmployeeChange(i, 'name', e.target.value)}
                            />
                            <label>Employee {i + 1} Email</label>
                            <input
                                className="inputs"
                                type="text"
                                placeholder={`Employee ${i + 1} Email`}
                                value={employee.email}
                                onChange={(e) => handleEmployeeChange(i, 'email', e.target.value)}
                            />
                            <label>Department</label>
                            <select
                                className="inputs"
                                value={employee.department_id}
                                onChange={(e) => handleEmployeeChange(i, 'department_id', e.target.value)}
                                required
                            >
                                <option value="">Select Department</option>
                                {formData.departments.map((department, index) => (
                                    <option key={index} value={index}>
                                        {department.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}
                    <button type="button" className="btn-pry" onClick={addEmployee}>
                        Add Employee
                    </button> */}

                    <button className="btn-pry submit" type="submit" disabled={loading}>
                        {loading ? 'Registering...' : 'Register'}
                    </button>
                    <NavLink to="/login"><p className="misc">Already have an account? Log in</p></NavLink>
                </form>
            </div>
        </div>
    );
};

export default Register;
